<template>
	<div class="entity-list">
		<action-list v-bind="parsedConfig">
			<template v-slot:invoiceIssuer="slotProps">
				{{slotProps.data.booking.issuer.name}}
			</template>
			<template v-slot:invoiceStatus="slotProps">
				{{formatInvoiceStatus(slotProps.data.status,slotProps.data.type)}}
			</template>

			<template v-slot:createdAt="slotProps">
				{{slotProps.data.createdAt | moment("DD-MM-YYYY")}}
			</template>
			<template v-slot:country="slotProps">
				{{countries[extractIdFromIri(slotProps.data.country)].name}}
			</template>
			<template v-slot:district="slotProps">
				<template v-if="typeof slotProps.data.district !== 'undefined' && slotProps.data.district !== null">{{slotProps.data.district.name}}</template>
				<template v-else>{{$t('sentences.noChosen')}}</template>
			</template>
			<template v-slot:districts="slotProps">
				{{slotProps.data.districts.length}}
			</template>
			<template v-slot:issuer="slotProps">
				{{slotProps.data.issuer.name}}
			</template>
			<template v-slot:status="slotProps">
				{{formatBookingStatus(slotProps.data.status)}}
			</template>
			<template v-slot:companyName="slotProps">
				{{slotProps.data.company.name}}
			</template>
			<template v-slot:users="slotProps">
				{{slotProps.data.users.length}}
			</template>
			<template v-slot:pilots="slotProps">
				{{slotProps.data.pilots.length}}
			</template>
			<template v-slot:primaryRole="slotProps">
				{{$t('roles.'+slotProps.data.primaryRole)}}
			</template>
			<template v-slot:machineStatus="slotProps">
				{{formatMachineStatus(slotProps.data.status)}}
			</template>
			<template v-slot:userStatus="slotProps">
				{{formatMachineStatus(slotProps.data.state)}}
			</template>
			<template v-slot:companyName="slotProps">
				<template v-if="typeof slotProps.data.company !== 'undefined' && slotProps.data.company !== null">{{slotProps.data.company.name}}</template>
				<template v-else>{{$t('sentences.noChosen')}}</template>
			</template>
			<template v-slot:dateAndTimeSlots="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentTime(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">{{formatSegmentTime(reservation.slot)}} (R)</p>
			</template>
			<template v-slot:dateAndTimeSlotDrivers="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentPilot(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">-</p>
			</template>
			<template v-slot:districts="slotProps">
				<p v-for="(district,index) in slotProps.data.districts" :key="district['@id']" class="list-internal-line">{{district.name}}</p>
			</template>
			<template v-slot:price="slotProps">
				{{slotProps.data.amountFormatted}}
			</template>
			<template v-slot:machineName="slotProps">
				{{slotProps.data.machine.name}}
			</template>
			<template v-slot:companyType="slotProps">
				{{formatCompanyType(slotProps.data.type)}}
			</template>
			<template v-slot:buttons="slotProps">
				<template v-if="entityType === 'invoices'">
					<ChangeInvoiceStatusButton v-if="hasGlobalSuperAdmin" :invoice="slotProps.data" class="mr-2" @statusChanged="reloadTrigger = reloadTrigger+1"  />
					<small-button :class="{'hide-completed': slotProps.data.status === 3}" :text="$t('terms.pdf')"  @click.native="downloadPdf(slotProps.data)" :loader-active="downloading.indexOf(slotProps.data.id) !== -1"  />
				</template>
			</template>
		</action-list>
	</div>
</template>

<script>
	import isGranted from "@/mixin/isGranted";
	import permissions from "@/permissions";
	import ActionList from "@/components/list/ActionList";
	import {buildIRI} from "@/helperFunctions";
	import entityMap from "@/entityMap";
	import {mapGetters, mapState} from "vuex";
	import {extractIdFromIri} from "@/helperFunctions";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import rules from "@/rules";
	import permissionGroups from "@/permissionGroups";
	import SmallButton from "@/components/buttons/SmallButton";
	import downloadMethods from "@/mixin/downloadMethods";
	import ChangeInvoiceStatusButton from "@/components/buttons/ChangeInvoiceStatusButton";
	export default {
		name: "EntityList",
		components: {ChangeInvoiceStatusButton, SmallButton, ActionList},
		mixins: [isGranted,dataConversionMethods,downloadMethods],
		data(){
			return {
				rules: rules,
				reloadTrigger: 0,
				config: {
					bookings: {
						entity: 'Booking',
						title: this.$tc("terms.booking",2),
						allowAsParent: false,
						create: {
							link: 'createBooking',
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.BOOKING,permissions.BOOKING.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
							{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
							{name:'casenumber',header:this.$t('listHeaders.case')},
							{name:'address',header:this.$t('listHeaders.address')},
							{name:'issuer',header:this.$t('listHeaders.createdBy')},
							{name:'companyName',header:this.$t('listHeaders.company')},
							{name:'status',header:this.$t('listHeaders.status')},
						],
						staticParameters:{
							explode: 'user:name,company:name,bookingSegment:date,bookingSegment:pilot,bookingSegment:startHour,bookingSegment:endHour,bookingSegmentReservation:slot,slot:date,slot:startHour,slot:endHour',
							'order[createdAt]':'desc'
						},
						rowStateFilters:{
							completed: {property: 'status', value: [2,3], compare: 'in'}
						},
						itemLink: {
							route: 'booking-view',
							parameters: {
								id: 'id'
							}
						},
						deleteHeadline: this.$t('terms.cancel')
					},
					companies: {
						entity: 'Company',
						title: this.$tc("terms.company",2),
						allowAsParent: true,
						create: {
							route: 'company-add',
							permissions: [permissions.SCREEN.COMPANY,permissions.COMPANY.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.COMPANY,permissions.COMPANY.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'cvr',header:this.$t('listHeaders.cvr')},
							{name:'billingEmail',header:this.$t('listHeaders.email')},
							{name:'users',header:this.$tc('terms.employee',2)},
							{name:'createdAt',header:this.$t('listHeaders.createdAt')},
						],
						filters: [
							{
								type:'entity',
								label:this.$t('listHeaders.country'),
								name: 'bookings.district.country',
								entity:"Country",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							},
							{
								type:'entity',
								label:this.$tc('listHeaders.district'),
								name: 'bookings.district',
								entity:"District",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id",
								filterProperty: 'country',
								filterValue: 'bookings.district.country'
							},
						],
						itemLink: {
							route: 'company-view',
							parameters: {
								id: 'id'
							}
						},
					},
					countries: {
						entity: 'Country',
						title: this.$tc("terms.country",2),
						allowAsParent: false,
						create: {
							route: 'country-add',
							permissions: [permissions.SCREEN.COUNTRY,permissions.COUNTRY.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.COUNTRY,permissions.COUNTRY.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'countryCode',header:this.$t('listHeaders.code')},
							{name:'districts',header:this.$tc('listHeaders.district',2)}
						],
						filters:[
							{
								type: 'search',
								name: 'name_like',
								text: 'Search'
							}
						],
						itemLink: {
							route: 'country-view',
							parameters: {
								id: 'id'
							}
						},
					},
					districts: {
						entity: 'District',
						title: this.$tc("terms.district",2),
						allowAsParent: true,
						create: {
							route: 'district-add',
							permissions: [permissions.SCREEN.DISTRICT,permissions.DISTRICT.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.DISTRICT,permissions.DISTRICT.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'country',header:this.$t('listHeaders.country')},
							{name:'pilots',header:this.$tc('listHeaders.driver',2)},
						],
						filters:[
							{
								type:'entity',
								label:this.$t('listHeaders.country'),
								name: 'country_equals',
								entity:"Country",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							},
							{
								type: 'search',
								name: 'name_like',
								text: 'Search'
							}
						],
						itemLink: {
							route: 'district-view',
							parameters: {
								id: 'id'
							}
						},
					},
					haulers: {
						entity: 'Hauler',
						title: this.$tc("terms.haulier",2),
						allowAsParent: false,
						create: {
							route: 'hauler-add',
							permissions: [permissions.SCREEN.HAULER,permissions.HAULER.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.HAULER,permissions.HAULER.DELETE]
						},
						filters: [
							{
								type:'entity',
								label:this.$tc('listHeaders.district',1),
								name: 'districts',
								entity:"District",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							}
						],
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'contactPerson',header:this.$t('listHeaders.contactPerson')},
							{name:'contactEmail',header:this.$t('listHeaders.contactEmail')},
							{name:'contactPhone',header:this.$t('listHeaders.contactPhone')},
							{name:'districts',header:this.$tc('terms.district',2)}
						],
						rowStateFilters:{
							completed: {property: 'active', value: [false], compare: 'in'}
						},
						staticParameters:{
							explode: 'district:name'
						},
						itemLink: {
							route: 'hauler-view',
							parameters: {
								id: 'id'
							}
						},
					},
					invoices: {
						entity: 'Invoice',
						title: this.$tc("terms.invoice",2),
						allowAsParent: false,
						columns: [
							{name:'id',header:'#ID'},
							{name:'type',header:this.$t('listHeaders.type')},
							{name:'casenumber',header:this.$t('listHeaders.case')},
							{name:'price',header:this.$t('listHeaders.price')},
							{name:'invoiceIssuer',header:this.$t('listHeaders.booker')},
							{name:'invoiceStatus',header:this.$t('listHeaders.status')},
						],
						filters: [
							{
								type:'entity',
								label:this.$tc('listHeaders.company',1),
								name: 'booking.company',
								entity:"Company",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							},
							{
								type:'entity',
								label:this.$tc('listHeaders.district',1),
								name: 'booking.district',
								entity:"District",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							}
						],
						staticParameters:{
							explode: 'user:name,booking:issuer,booking:casenumber',
							'order[createdAt]': 'desc',
						},
						rowStateFilters:{
							completed: {property: 'status', value: [3], compare: 'in'}
						},
						showCustomButtons: true
					},
					machines: {
						entity: 'Machine',
						title: this.$tc("terms.machine",2),
						allowAsParent: false,
						create: {
							route: 'machine-add',
							permissions: [permissions.SCREEN.MACHINE,permissions.MACHINE.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.MACHINE,permissions.MACHINE.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'createdAt',header:this.$t('listHeaders.createdAt')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'district',header:this.$tc('listHeaders.district',1)},
							{name:'machineStatus',header:this.$tc('listHeaders.status')},
						],
						filters: [
							{
								type:'entity',
								label:this.$t('listHeaders.country'),
								name: 'district.country',
								entity:"Country",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id"
							},
							{
								type:'entity',
								label:this.$tc('listHeaders.district'),
								name: 'district',
								entity:"District",
								searchProperty:"name",
								displayProperty:"name",
								valueProperty:"id",
								filterProperty: 'country',
								filterValue: 'district.country'
							},
						],
						staticParameters:{
							explode: 'district:name,company:name'
						},
						rowStateFilters:{
							completed: {property: 'status', value: 0}
						},
						itemLink: {
							route: 'machine-view',
							parameters: {
								id: 'id'
							}
						},
					},
					trailers: {
						entity: 'Trailer',
						title: this.$tc("terms.trailer",2),
						create: {
							route: 'trailer-add',
							permissions: [permissions.SCREEN.TRAILER,permissions.TRAILER.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.TRAILER,permissions.TRAILER.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'serialNumber',header:this.$t('listHeaders.serial')},
							{name:'numberPlate',header:this.$t('listHeaders.numberPlate')},
							{name:'machineName',header:this.$t('listHeaders.machine')},
							{name:'createdAt',header:this.$t('listHeaders.createdAt')},
						],
						staticParameters:{
							explode: 'machine:name'
						},
						itemLink: {
							route: 'trailer-view',
							parameters: {
								id: 'id'
							}
						},
					},
					users: {
						entity: 'User',
						title: this.$tc("terms.user",2),
						allowAsParent: false,
						create: {
							route: 'user-add',
							permissions: [permissions.SCREEN.USER,permissions.USER.CREATE]
						},
						delete: {
							permissions: [permissions.SCREEN.USER,permissions.USER.DELETE]
						},
						columns: [
							{name:'id',header:this.$t('listHeaders.id')},
							{name:'name',header:this.$t('listHeaders.name')},
							{name:'email',header:this.$t('listHeaders.email')},
							{name:'mobile',header:this.$t('listHeaders.phone')},
							{name:'primaryRole',header:this.$tc('listHeaders.role',2)},
							{name:'createdAt',header:this.$t('listHeaders.createdAt')},
							{name:'userStatus',header:this.$t('listHeaders.status')},
						],
						filters:[
							{
								type: 'select',
								options: [
									{
										value: permissionGroups.PILOT.key,
										text: this.$t(permissionGroups.PILOT.text)
									},
									{
										value: permissionGroups.DISTRICT_MANAGER.key,
										text: this.$t(permissionGroups.DISTRICT_MANAGER.text)
									},
									{
										value: permissionGroups.COMPANY.key,
										text: this.$t(permissionGroups.COMPANY.text)
									},
									{
										value: permissionGroups.ENTREPRENEUR.key,
										text: this.$t(permissionGroups.ENTREPRENEUR.text)
									},
									{
										value: permissionGroups.PARTNER.key,
										text: this.$t(permissionGroups.PARTNER.text)
									}

								],
								name: 'hasRole',
								text: this.$t('terms.role')
							},
							{
								type: 'search',
								name: 'name_like',
								text: 'Search'
							}
						],
						rowStateFilters:{
							completed: {property: 'state', value: 0}
						},
						itemLink: {
							route: 'user-view',
							parameters: {
								id: 'id'
							}
						},
					},
				}
			}
		},
		computed: {
			...mapState('concrete', {
				countries: 'countries',
				apiKey: 'apiKey',
				locale: 'locale',
				viewingEntity: 'viewingEntity',
			}),
			...mapGetters('concrete',{
				viewUsersAsEmployees: 'viewUsersAsEmployees',
				hasGlobalSuperAdmin: 'hasGlobalSuperAdmin',
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			entityType(){
				return this.$route.params.entityType;
			},
			parentType(){
				return (typeof this.$route.params.parentType !== 'undefined') ? this.$route.params.parentType : false;
			},
			parentEntityIRI() {
				return (this.parentType !== false && typeof this.$route.params.id !== 'undefined') ? buildIRI(this.$route.params.id,this.config[this.parentType].entity) : false ;
			},
			parentConfig(){
				return this.parentType !== false ? this.config[this.parentType] : false;
			},
			entityConfig(){
				return this.config[this.entityType];
			},
			parsedConfig(){
				// Clone object to break references.
				let config = Object.assign({}, this.entityConfig);

				if(typeof config['pr-page'] === 'undefined')
					config['pr-page'] = 10;

				if(typeof config['paginated'] === 'undefined')
					config['paginated'] = true;

				if(typeof config['show-delete'] === 'undefined')
					config['show-delete'] = this.mayDelete;

				if(this.parentConfig !== false)
				{
					const total = typeof config['staticParameters'] !== 'undefined' ? config['staticParameters'] : {} ;
					const parent = this.buildParentParameter();
					Object.keys(parent).forEach((key) => {
						total[key] = parent[key];
					});
					config['staticParameters'] = total;
				}
				
				if(this.viewUsersAsEmployees && this.entityType === 'users')
					config['title'] = this.$tc("terms.employee",2);

				if(typeof config['create'] !== 'undefined' && typeof config['create']['link'] !== 'undefined' && config['create']['link'] === 'createBooking')
				{
					config['create'] = Object.assign({},config['create'])
					config['create']['link'] = process.env.VUE_APP_CONCRETE_BOOKING_URL+this.locale+'?userToken='+this.apiKey
				}

				if(this.entityType === 'bookings' && this.hasGlobalSuperAdmin)
				{
					config['columns'] = [
						{name:'id',header:this.$t('listHeaders.id')},
						{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
						{name:'casenumber',header:this.$t('listHeaders.case')},
						{name:'address',header:this.$t('listHeaders.address')},
						{name:'companyName',header:this.$t('listHeaders.company')},
						{name:'status',header:this.$t('listHeaders.status')},
					];
				}
				else if(this.entityType === 'companies' && this.hasGlobalSuperAdmin)
					config['columns'].push({name:'companyType',header:this.$t('listHeaders.type')});
				else if(this.entityType === 'machines' && this.hasGlobalSuperAdmin)
					config['columns'].splice(2,0,{name:'companyName',header:this.$t('listHeaders.company')});
				else if (this.viewingEntity !== false && this.viewingEntityIRIParts.entity === 'Company' && this.entityType === 'users')
				{
					if(this.isGranted(permissions.DISTRICT.READ,this.parentEntityIRI))
					{
						if(typeof config.staticParameters !== 'undefined' && typeof config.staticParameters.companies !== 'undefined')
							delete config.staticParameters.companies;
					}
				}

				config.reloadTrigger = this.reloadTrigger;

				return config;
			},
			mayDelete(){
				if(typeof this.entityConfig.delete === 'undefined')
					return false;

				return (this.parentEntityIRI === false) ? this.isGranted(this.entityConfig.delete.permissions) : this.isGranted(this.entityConfig.delete.permissions,this.parentEntityIRI) ;
			},
		},
		methods: {
			buildParentParameter(){
				let parameters = {};

				// @todo setup conditions where it isn't a simple 1-1 endpoint=value case, if any exists.
				if(this.entityType === 'machines' && this.parentType === 'districts')
				{
					parameters['district'] = this.$route.params.id;
				}
				else
				{
					parameters[entityMap[this.parentConfig.entity].endpoint] = this.$route.params.id;
				}

				return parameters;
			},
			extractIdFromIri(iri){
				return extractIdFromIri(iri);
			}
		}
	};
</script>

<style scoped>

</style>
