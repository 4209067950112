<template>
	<small-button :text="$t('sentences.changeStatus')"  @click.native="promptStatusChange" :loader-active="isChanging" />
</template>

<script>
import SmallButton from "@/components/buttons/SmallButton";
import dataConversionMethods from "@/mixin/dataConversionMethods";
export default {
	name: "ChangeInvoiceStatusButton",
	components: {SmallButton},
	mixins: [dataConversionMethods],
	data(){
		return {
			isChanging: false
		}
	},
	computed: {
		inputOptions(){
			let options = {};
			[0,2,3].forEach(status => {
				options[status] = this.formatInvoiceStatus(status,this.invoice.type)
			})
			return options;
		}
	},
	methods: {
		promptStatusChange(){
			this.$swal.fire({
				title: this.$t('sentences.changeInvoiceStatus'),
				text: this.$t('sentences.youAreChangingTheStatusOfInvoiceX',{id:this.invoice.id}),
				input: 'select',
				inputOptions: this.inputOptions,
				inputPlaceholder: this.$t('sentences.changeStatus'),
				showCancelButton: true,
				inputValue: this.invoice.status,
				showLoaderOnConfirm: true,
				confirmButtonColor: "#495760",
				confirmButtonText: this.$t("terms.ok"),
				preConfirm: (status) => {
					return this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload(status))
					.then(response => {
						if (typeof response['@id'] === 'undefined') {
							throw new Error('Unknown error')
						}
						return response
					})
					.catch(error => {
						this.$swal.showValidationMessage(
							`Request failed: ${error}`
						)
					})
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$emit('statusChanged');
					this.$swal.fire({
						icon: 'success',
						title: this.$t("alerts.invoiceStatusWasUpdatedTitle"),
						text: this.$t("alerts.invoiceStatusWasUpdatedDescription"),
						confirmButtonText: this.$t("terms.ok"),
						confirmButtonColor: "#495760"
					})
				}
			});
		},
		buildPayload(status){
			return {
				entity: {
					'@type': 'Invoice',
					'id': this.invoice.id
				},
				method: 'put',
				payload: {
					status: Number(status),
				}
			};
		}
	},
	props: {
		invoice: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>

</style>