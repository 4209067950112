export default {
	data(){
		return {
			downloading: []
		}
	},
	methods: {
		downloadPdf(entity){
			this.downloadFile(process.env.VUE_APP_CONCRETE_API_URL +'/download/invoice/'+entity['id'],entity);
		},
		downloadFile(urlToSend,entity) {
			this.downloading.push(entity['id']);
			let req = new XMLHttpRequest();
			req.open("GET", urlToSend, true);
			req.setRequestHeader('X-AUTH-TOKEN',this.apiKey);
			req.responseType = "blob";
			req.onload = (event) => {
				if(req.status === 200)
				{
					const blob = req.response;
					const fileName = this.formatInvoiceType(entity.type).toLowerCase()+"-"+entity['id']+".pdf";
					const link = document.createElement('a');
					link.href=window.URL.createObjectURL(blob);
					link.download=fileName;
					link.click();
				}
				this.downloading.splice(this.downloading.indexOf(entity['id']),1);
			};
			
			req.send();
		}
	}
}
